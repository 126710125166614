import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30')
];

export const server_loads = [0,2];

export const dictionary = {
		"/(blank)": [29,[4],[5]],
		"/(app)/actions": [7,[2]],
		"/(app)/actions/[actionId]": [~8,[2]],
		"/(app)/admin": [~9,[2]],
		"/(app)/admin/feature-flags": [~10,[2]],
		"/(app)/admin/one-shots": [11,[2]],
		"/(app)/admin/users": [~12,[2]],
		"/(app)/admin/users/[userId]": [~13,[2]],
		"/(app)/admin/waitlist": [~14,[2]],
		"/(app)/contexts/[contextId]": [~15,[2]],
		"/(blank)/css/colors": [30,[4],[5]],
		"/(app)/dashboard": [~16,[2]],
		"/(app)/inbox": [~17,[2]],
		"/(app)/keyboard-shortcuts": [18,[2]],
		"/(app)/projects": [~19,[2]],
		"/(app)/projects/[projectId]": [~20,[2]],
		"/(app)/report-bug": [~21,[2]],
		"/(app)/review": [~22,[2]],
		"/(app)/settings": [~23,[2,3]],
		"/(app)/settings/account": [24,[2,3]],
		"/(app)/settings/contexts": [25,[2,3]],
		"/(app)/settings/customizations": [26,[2,3]],
		"/(app)/settings/subscription": [27,[2,3]],
		"/(app)/waiting": [~28,[2]],
		"/(app)/[...page]": [~6,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';